import { useContext } from 'react';
import AuthContext, { OAuth2ContextType } from '../contexts/OAuth2Context';
import { remoteConfigData } from '../config';
import KeycloakContext from '../contexts/KeycloakContext';


const useAuth = () => {
  if (remoteConfigData.enableKeycloak()) {
    return  useContext<OAuth2ContextType>(KeycloakContext);
  }
  return useContext<OAuth2ContextType>(AuthContext);
};

export default useAuth;
