import * as superagent from 'superagent';
import { hasValue } from 'components/inputs/inputHelpers';
import Toast from './Toast';
import authService from 'contexts/authService';
import { User } from 'oidc-client-ts';
import { remoteConfigData } from '../config';
import keycloakAuthService from '../contexts/KeycloakAuthService';

export const getToken = async (): Promise<string | null> => {
  if (remoteConfigData.enableKeycloak()) {
    return keycloakAuthService.getToken();
  }
  const user: User = await authService.getUser();
  if (!user) return undefined;
  return user.access_token;
};


type CallbackFunction = (data?: any) => void;
type CustomErrorCallback = (err: any, res: superagent.Response) => void;
type EndCallback = (data?: any) => void;

// TODO handle all errors
export const isStandardValidationError = (data: any): boolean => {
  const error: IClientError = data;
  if (hasValue(error.title) && error.title.indexOf('validation errors') > -1) {
    let { title } = error;

    Object.entries(error.errors).forEach((it) => {
      const [, value] = it;
      title = `${title}\n${value}`;
    });
    return true;
  }
  return false;
};

const isClientError = (res: any) => (res && res.clientError) || (res && res.notAcceptable) || (res && res.error);

export const handleError = (err: any, res: superagent.Response) => {
  const defaultMessage = 'Invalid request, please contact admin';
  if (res && res.forbidden) {
    Toast.error('Authorization Error');
  } else if (res && res.unauthorized) {
    const { message } = res.body;
    Toast.error(message ?? 'Authentication Error');
  } else if (res && res.badRequest) {
    if (isStandardValidationError(res.body)) {
      // Handled in the check (isStandardValidationError)
      return;
    }
    const { message, errors = [] } = res.body;
    let msg = `${message}\n`;

    errors.forEach((it) => {
      const error = Object.values(it)[0];
      msg += (`${error}\n`);
    });

    Toast.error(msg || defaultMessage);
  } else if (isClientError(res)) {
    const { message } = res.body || {};
    Toast.error(message || defaultMessage);
  } else {
    const message = err.message || 'Unknown error, contact admin';
    const finalMessage = message.indexOf('offline') !== -1
      ? "Can't reach server, Check connectivity"
      : message;
    Toast.error(finalMessage);
  }
};

export const handleErrorMobile = (err: any, res: superagent.Response) => {
  const defaultMessage = 'Invalid request, please contact admin';
  if (res && res.forbidden) {
    Toast.error('Authentication Error');
  } else if (res && res.unauthorized) {
    const { message } = res.body;
    Toast.error(message ?? 'Authentication Error');
    // window.location.reload();
  } else if (res && res.badRequest) {
    if (isStandardValidationError(res.body)) {
      // Handled in the check (isStandardValidationError)
      return;
    }
    const { message, errors = [] } = res.body;
    let msg = `${message}\n`;

    errors.forEach((it) => {
      const error = Object.values(it)[0];
      msg += (`${error}\n`);
    });

    Toast.error(msg || defaultMessage);
  } else if (isClientError(res)) {
    const { message } = res.body || {};
    Toast.error(message || defaultMessage);
  }
};

export interface IClientError {
  type: string;
  title: string;
  status: number;
  traceId: string;
  errors: any;
}

export const handleBadRequestError = (err: any, res: superagent.Response, cb: (data: any) => void) => {
  const defaultMessage = 'Invalid request, please contact admin';
  if ((res && res.forbidden) || (res && res.unauthorized)) {
    Toast.error('Authentication Error');
  } else if (res && res.badRequest) {
    if (!isStandardValidationError(res.body)) {
      cb(res.body);
    }
  } else if (isClientError(res)) {
    const { message } = res.body || {};
    Toast.error(message || defaultMessage);
  } else {
    const message = err.message || 'Unknown error, contact admin';
    const finalMessage = message.indexOf('offline') !== -1
      ? "Can't reach server, Check connectivity"
      : message;
    Toast.error(finalMessage);
  }
};

const timeout = 0;
export const isAuthError = (err: any, res: superagent.Response) => {
  if (err) {
    console.log(err);
    return false;
  }
  return (res && res.forbidden) || (res && res.unauthorized);
};

export const handleResponse = (callBack: CallbackFunction, errorCallBack?: CustomErrorCallback, endCallBack?: EndCallback) => (err: any, res: superagent.Response) => {
  try {
    if (err || !res.ok) {
      if (errorCallBack) {
        errorCallBack(err, res);
      } else {
        handleError(err, res);
      }
    } else {
      callBack(res.body);
    }
  } catch (e) {
    console.error('Failed to process response', e);
  } finally {
    if (endCallBack) {
      endCallBack();
    }
  }
};

export const get = (url: string, callBack: CallbackFunction, errorCallBack?: CustomErrorCallback, endCallBack?: EndCallback) => {
  getToken().then((token) => {
    superagent.get(url)
      .set('Authorization', `Bearer ${token}`)
      .set('Accept', 'application/json')
      .timeout(timeout)
      .end(handleResponse(callBack, errorCallBack, endCallBack));
  });
};

export const search = (url: string, data: any, callBack: CallbackFunction, errorCallBack?: CustomErrorCallback, endCallBack?: EndCallback) => {
  getToken().then((token) => {
    superagent.get(url)
      .set('Authorization', `Bearer ${token}`)
      .set('Accept', 'application/json')
      .query(data)
      .timeout(timeout)
      .end(handleResponse(callBack, errorCallBack, endCallBack));
  });

};

export const post = (url: string, data: any, callBack: CallbackFunction, errorCallBack?: CustomErrorCallback, endCallBack?: EndCallback) => {
  getToken().then((token) => {
    superagent.post(url)
      .set('Authorization', `Bearer ${token}`)
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .send(data)
      .timeout(timeout)
      .end(handleResponse(callBack, errorCallBack, endCallBack));
  });

};

export const postFile = (url: string, data: any, callBack: CallbackFunction, errorCallBack?: CustomErrorCallback, endCallBack?: EndCallback) => {
  getToken().then((token) => {
    superagent.post(url)
      .set('Authorization', `Bearer ${token}`)
      .set('Accept', 'application/json')
      .send(data)
      .timeout(timeout)
      .end(handleResponse(callBack, errorCallBack, endCallBack));
  });

};

export const putFile = (url: string, data: any, callBack: CallbackFunction, errorCallBack?: CustomErrorCallback, endCallBack?: EndCallback) => {
  getToken().then((token) => {
    superagent.put(url)
      .set('Authorization', `Bearer ${token}`)
      .set('Accept', 'application/json')
      .send(data)
      .timeout(timeout)
      .end(handleResponse(callBack, errorCallBack, endCallBack));
  });
  
};

export const put = (url: string, data: any, callBack: CallbackFunction, errorCallBack?: CustomErrorCallback, endCallBack?: EndCallback) => {

  getToken().then((token) => {
    superagent.put(url)
      .set('Authorization', `Bearer ${token}`)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .send(data)
      .timeout(timeout)
      .end(handleResponse(callBack, errorCallBack, endCallBack));
  });

};

export const del = (url: string, callBack: CallbackFunction, errorCallBack?: CustomErrorCallback, endCallBack?: EndCallback) => {
  getToken().then((token) => {
    superagent.delete(url)
      .set('Authorization', `Bearer ${token}`)
      .set('Accept', 'application/json')
      .timeout(timeout)
      .end(handleResponse(callBack, errorCallBack, endCallBack));
  });

};

export const delWithBody = (url: string, data: any, callBack: CallbackFunction, errorCallBack?: CustomErrorCallback, endCallBack?: EndCallback) => {
  getToken().then((token) => {
    superagent.delete(url)
      .set('Authorization', `Bearer ${token}`)
      .set('Accept', 'application/json')
      .send(data)
      .timeout(timeout)
      .end(handleResponse(callBack, errorCallBack, endCallBack));
  });
};

export const downLoad = (url: string, callBack: CallbackFunction, errorCallBack?: CustomErrorCallback, endCallBack?: EndCallback) => {
  getToken().then((token) => {
    superagent.get(url)
      .set('Authorization', `Bearer ${token}`)
      .responseType('blob')
      .end(handleResponse(callBack, errorCallBack, endCallBack));
  });
};

export const triggerDownLoad = (data: Blob, fileName = 'export.csv') => {
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(data);
  a.download = fileName;
  a.click();
};
