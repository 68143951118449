import { createContext, useCallback, useEffect, useState } from 'react';
import { User } from 'oidc-client-ts';
import { uniq } from 'lodash';
import authService from './authService';
import { AppUser } from '../types/user';
import { dataStrToArray } from '../utils/stringHelpers';
import { fetchAppUser } from '../utils/fetchHelpers';

type State = {
  isAuthenticated: boolean,
  isInitialized: boolean,
  platform: string,
  user?: AppUser
};


export type OAuth2ContextType = State & {
  loadUser: () => any,
  login: () => any,
  loginRedirectCallback: () => any,
  logout: () => any,
  logoutRedirectCallback: () => any,
  silentRedirectCallback: () => any,
};

const OAuth2Context = createContext<OAuth2ContextType>({
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  platform: 'OAuth2',
  loadUser: () => Promise.resolve(),
  login: () => Promise.resolve(),
  loginRedirectCallback: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  logoutRedirectCallback: () => Promise.resolve(),
  silentRedirectCallback: () => Promise.resolve()
});

const parseOidcUser = ({ profile }): AppUser => ({
  id: profile.sub,
  avatar: profile.picture,
  email: profile.email,
  name: profile.name,
  roles: dataStrToArray(profile.role),
  plan: '',
  partnerId: ''
});

const loadUser = async (): Promise<AppUser | undefined> => {
  try {
    const user: User = await authService.getUser();
    if (!user) return undefined;
    const oidcUser = parseOidcUser(user);
    const appUser = await fetchAppUser(oidcUser.id, user.access_token);
    return { ...oidcUser, roles: uniq([...oidcUser.roles, ...appUser?.roles || []]), partnerId: appUser.partnerId };
  } catch (e) {
    return undefined;
  }
};

export const AuthProvider = (props: any) => {
  const { children } = props;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [user, setUser] = useState<AppUser | null>(null);

  useEffect(() => {
    authService.initialize();
  }, []);

  const loadUserFromCache = useCallback(async () => {
    try {
      console.log('load user from cache auth...');
      const loadedUser = await loadUser();
      if (!!loadedUser) {
        setUser(loadedUser);
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        setUser(null);
      }
    } catch (err) {
      console.error(err);
      setIsAuthenticated(false);
      setUser(null);
    }

    setIsInitialized(true);
  }, []);

  useEffect(() => {
    const initialize = async () => {
      await loadUserFromCache();
    };
    initialize();
  }, []);

  const login = async () => {
    await authService.signinRedirect();
  };

  const logout = async () => {
    await authService.signout();
    setUser(null);
    setIsAuthenticated(false);
  };

  const loginRedirectCallback = async () => {
    await authService.signinRedirectCallback();
    await loadUserFromCache();
  };

  const silentRedirectCallback = async () => {
    await authService.silentRenewCallback();
    await loadUserFromCache();
  };

  const logoutRedirectCallback = async () => {
    await authService.signoutRedirectCallback();
    setUser(null);
    setIsAuthenticated(false);
  };

  return (
    <OAuth2Context.Provider
      value={{
        user,
        isAuthenticated,
        isInitialized,
        platform: 'OAuth2',
        loadUser,
        login,
        logout,
        loginRedirectCallback,
        logoutRedirectCallback,
        silentRedirectCallback
      }}
    >
      {children}
    </OAuth2Context.Provider>
  );
};

export default OAuth2Context;
