import type { FC } from 'react';
import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from './contexts/OAuth2Context';
import { StyledEngineProvider, ThemeProvider, useMediaQuery } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import GlobalStyles from 'components/GlobalStyles';
import SplashScreen from 'pages/authentication/SplashScreen';
import { remoteConfigData } from './config';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import routes from './routes';
import { createTheme } from './theme';
import ProfileValidator from './pages/authentication/profile/ProfileValidator';
import SettingsDrawer from './components/SettingsDrawer';
import { SelectedWalletProvider } from './pages/administration/wallets/selected-wallet/SelectedWalletContext';
import { KeycloakProvider } from './contexts/KeycloakContext';


const queryClient = new QueryClient();
const App: FC<React.PropsWithChildren<unknown>> = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();

  const { isInitialized, loadUser, isAuthenticated } = useAuth();
  useScrollReset();

  useEffect(() => {
    if (!isInitialized) {
      loadUser();
    }
  }, []);

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <SnackbarProvider
                        dense
                        maxSnack={3}
                    >
                        <ToastContainer/>
                        <GlobalStyles/>
                        {isAuthenticated && isLargeScreen && (<SettingsDrawer/>)}
                        {
                            isInitialized ?
                              (
                                    <ProfileValidator>
                                        <SelectedWalletProvider isAuthenticated={isAuthenticated}>
                                            {content}
                                        </SelectedWalletProvider>
                                    </ProfileValidator>
                              ) :
                              (
                                    <SplashScreen/>
                              )
                        }
                    </SnackbarProvider>
                </QueryClientProvider>
            </ThemeProvider>
        </StyledEngineProvider>
  );
};

export const AppContainer = ()=>{
  if (remoteConfigData.enableKeycloak())
    return <KeycloakProvider><App /></KeycloakProvider>;
  return <AuthProvider><App /></AuthProvider>;
};

export default AppContainer;
