import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getRemoteConfig } from 'firebase/remote-config';
import { getValue } from 'firebase/remote-config';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID
};

export const appSettings = {
  appName: 'ioTecPay'
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 60000; //3600000;

remoteConfig.defaultConfig = {
  'enable_keycloak': true,
  'enable_collection_preview': false,
  'enable_bank_operations': true,
  'enable_wallet_sms_notifications': true,
  'enable_wallet_email_notifications': false,
  'enable_top_ups_on_sidebar': true,
  'enable_liquidations_on_sidebar': true,
  'enable_wallet_access_control': false,
};

export const remoteConfigData = {
  enableKeycloak: () => {
    //return getValue(remoteConfig, 'enable_keycloak').asBoolean();
    return true;
  },
  enableCollectionPreview: () => {
    return getValue(remoteConfig, 'enable_collection_preview').asBoolean();
  },
  enableBankOperations: () => {
    return getValue(remoteConfig, 'enable_bank_operations').asBoolean();
  },
  enableWalletSmsNotifications: () => {
    return getValue(remoteConfig, 'enable_wallet_sms_notifications').asBoolean();
  },
  enableWalletEmailNotifications: () => {
    return getValue(remoteConfig, 'enable_wallet_email_notifications').asBoolean();
  },
  enableTopUpsOnSidebar: () => {
    return getValue(remoteConfig, 'enable_top_ups_on_sidebar').asBoolean();
  },
  enableLiquidationsOnSidebar: () => {
    return getValue(remoteConfig, 'enable_liquidations_on_sidebar').asBoolean();
  },
  enableWalletAccessControl: () => {
    return getValue(remoteConfig, 'enable_wallet_access_control').asBoolean();
  }
};
